html,body {
  min-height: 100%;
  width: 100%;
  background-color: #0060ad;
}
#root {
  display: flex;
  min-height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.page {
  width: 100%;
  min-height: 100%;
}
.header {
  height: 50px;
  box-sizing: border-box;
  padding-top: 10px;
  text-align: center;
}
.header img {
  height: 40px;
  width: auto;
}
.download {
  clear: both;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}
.download img {
  max-width: 150px;
  width: 40%;
  height: auto;
  margin: 0 10px;
}
.footer {
  clear: both;
  box-sizing: border-box;
  padding-top: 10px;
  height: 50px;
  text-align: center;
}
.footer img {
  height: 26px;
  width: auto;
}
.fullForm {
  clear: both;
  box-sizing: border-box;
  width: 100%;
  max-width: 800px;
  padding: 15px;
  margin: 0 auto;
}
.formSelect {
  width: 100%;
}
.formSelect .MuiInput-underline:before {
  border: 0;
}
.formSelect .MuiInput-underline:hover:not(.Mui-disabled):before {
  border: 0;
}
.formSelect .MuiInputBase-input,
.formSelect .MuiSelect-select:focus {
  background-color: #FFF;
  color: #005191;
}
.formInput {
  width: 100%;
}
.formSelect.MuiFormControl-root,
.formInput .MuiInputBase-root {
  box-sizing: border-box;
  background-color: #FFFFFF;
  border-radius: 10px;
  padding: 5px 10px;
  margin-top: 26px;
  margin-bottom: 15px;
  width: 100%;
}
.formInput input {
  color: #005191;
  font-weight: bold;
  font-size: 18px;
}
.fullForm label {
  display: block;
  color: #FFFFFF;
  font-size: 24px;
  padding: 0 0 25px 10px;
}
.fullForm .MuiFormLabel-root.Mui-focused {
  color: #FFF;
}
.fullForm .MuiInputAdornment-root {
  color: #005191;
}
.formCheckbox {
  background-color: #FFFFFF;
  border-radius: 10px;
  margin-top: 10px!important;
}
.formCheckbox > label {
  padding: 10px 0 10px 10px;
  color: #005191;
}
.formCheckbox .MuiIconButton-label {
  color: #005191;
}
.formCheckbox .MuiCheckbox-root {
  float: left;
  width: 15%;
  box-sizing: border-box;
}
.formCheckbox .MuiFormControlLabel-label {
  float: left;
  width: 85%;
  box-sizing: border-box;
}
button.formSubmit {
  clear: both;
  float: right;
  margin: 20px 0!important;
  color: #005191;
  background-color: #FFF;
}
button.MuiButton-contained.Mui-disabled {
  background-color: #cecece;
}

/* confirmation */
.confirmation {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  background-color: #cecece;
  color: #005191;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 10px;
}
.confirmation .conf-business {
  font-size: 28px;
  margin: 5px 0 15px 0;
}
.confirmation .conf-data {
  margin: 2px 0 10px 0;
  font-size: 16px;
  font-weight: normal;
}
.confirmation .conf-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 10px;
  margin: 20px auto 0 auto;
  max-width: 300px;
}
.conf-buttons .sayNo {
  color: #FFFFFF;
  background-color: #c70505;
}
.conf-buttons .sayYes {
  color: #FFFFFF;
  background-color: #3bc752;
}

/* success */
.success {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  background-color: #3bc752;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
  border-radius: 10px;
}
.success .conf-business {
  font-size: 28px;
  margin: 5px 0 15px 0;
}
.suc6 .haveSent {
  width: 100%;
  box-sizing: border-box;
  padding: 15px 10px;
  color: #FFFFFF;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  margin-top: 0px;
}
.suc6 .conf-data {
  color: #FFFFFF;
  margin: 2px 0 10px 0;
  font-size: 16px;
  font-weight: normal;
}
.suc6 .conf-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 10px;
  margin: 0 auto 20px auto;
  max-width: 300px;
}
.suc6 .conf-buttons .back {
  color: #FFFFFF;
  background-color: #005191;
}

.conf-buttons .sayYes.animated .MuiButton-label {
  -webkit-animation: rotateit 1.3s linear infinite;
  -moz-animation: rotateit 1.3s linear infinite;
  animation: rotateit 1.3s linear infinite;
}
@-webkit-keyframes rotateit {
     from {
          -webkit-transform: rotate(360deg);
     }
     to {
          -webkit-transform: rotate(0deg);
     }
}
@-moz-keyframes rotateit {
     from {
          -moz-transform: rotate(360deg);
     }
     to {
          -moz-transform: rotate(0deg);
     }
}
@keyframes rotateit {
     from {
          transform: rotate(360deg);
     }
     to {
          transform: rotate(0deg);
     }
}

@media (min-width: 600px) {
  .bottom {
    clear: both;
    box-sizing: border-box;
    width: 100%;
    max-width: 800px;
    padding: 0 15px 0 0;
    margin: 0 auto;
  }
  .download {
    float: left;
    text-align: left;
  }
  .footer {
    float: right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    clear: unset;
  }
}
